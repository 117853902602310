import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import VideoPlaceholder from "../images/video-placeholder.jpg"
import Layout from '../layouts/layout'

const LegalNoticesPage = () => {
    const data = useStaticQuery(graphql`
        query LegalNoticesQuery {
            contentfulLayout(contentful_id: {eq: "1T5bU2GbxSkJcRHSWkpxlD"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                            description
                        }
                    }
                }
                hero {
                    header_title
                }
            }
        }
    `)

    const page = data.contentfulLayout

    return(
        <Layout>
            <Helmet>
                <title>O3 Edge | Legal Notices</title>
            </Helmet>
            <div className="hero footer-page" style={{ backgroundImage: `url(${VideoPlaceholder})`}}>
                <Container>
                    <Row>
                        <Col className="py-5 text-center">
                            <h1>{page.hero.header_title}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                 <Row className="py-5">
                    <Col>
                        <h2 className="pb-3">{page.contentModule[0].title}</h2>
                        <p>{page.contentModule[0].description.description}</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default LegalNoticesPage